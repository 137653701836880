<template>
  <div>
    <!--<active-jobs />-->
    <b-tabs content-class="mt-1">
      <!-- This tabs content will always be mounted -->
      <b-tab title="Active Jobs">
        <b-row>
          <b-col cols="12">
            <active-jobs />
          </b-col>
        </b-row>
      </b-tab>

      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <b-tab
        title="Closed Jobs"
        id="closed-jobs"
        lazy
      >
        <b-row>
          <b-col cols="12">
            <closed-jobs />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Archived Jobs"
        lazy
      >
        <b-row>
          <b-col cols="12">
            <draft-jobs />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import ActiveJobs from './ActiveJobs.vue'
import ClosedJobs from './ClosedJobs.vue'
import DraftJobs from './DraftJobs.vue'
//import axios from 'axios'
import JobService from '@/services/job-service'
import UserService from '@/services/user-service'

export default {
  components: {
    ActiveJobs,
    ClosedJobs,
    DraftJobs,
    BTabs,
    BTab,
    // BAlert,
    BRow,
    BCol,
  },
  data() {
    return {
    //  id: '',
      jobData: '',
    }
  },
  created() {
    //const accessToken = localStorage.getItem('accessToken')
    //console.log(accessToken)
    console.log(new Date().getTime())
    // this.id = this.$route.params.id
    /*this.$http.get('/jobs/data')
      .then(response => {
        this.jobData = response.data.jobs
        // console.log('data b4', this.data)
        // this.jobData = this.data.jobs.filter(item => item.jobId === this.id)
        console.log('job data after', this.jobData)
        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        // const userData = getUserData()
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })*/
      //const accessToken = localStorage.getItem('accessToken')
      /*JobService.getAllJobs('null').then(res => {
        //console.log(res.data)
        
        if(res.status === "OK") {
          this.jobData = res.data;
          //this.jobData = delete this.jobData['recruiters']
          //console.log(this.jobData)
          /*for(let i = 0; i < jobs.length; i++) {
            let j = jobs[i];
            const recruiters = jobs[i].recruiters
            console.log(recruiters)
            UserService.getUserDetail(recruiters).then(res =>{
              console.log(res.data)
              const newRecruiters = res.data
              delete jobs[i].recruiters
              const newJob = { ...j, recruiters: newRecruiters }
              console.log(newJob)
              this.jobData.push(newJob)
              console.log(this.jobData)
          })
          }
        } 

      })*/

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
